import { graphql, useStaticQuery } from 'gatsby';
import React, { useMemo, createContext } from 'react';

export const pageDataContext = createContext({
  // 通用展示数据
  common: {},
  // 页面展示数据
  data: {},
  // 页面location
  location: {},
  // 页面去除locale标志后的路径
  pathname: '',
});

export function PageDataProvider({ location, pageContext, data, children }) {
  const { pathname } = pageContext;

  // common Data
  const { allContentfulCommon } = useStaticQuery(graphql`
    query CommonQuery {
      allContentfulCommon {
        nodes {
          language
          edit
          signOut
          overview
          submit
          cancel
          save
          delete
          email
          firstName
          lastName
          phoneNumber
          birthday
          country
          state
          city
          address
          zipCode
          apartment
          required
          needHelp
          contactUs
          copy
          copied
          discount
          viewAll
          password
          selectCountry
          endingWith
          checkMore
          rules
          cookiesSettings
        }
      }
    }
  `);

  const commonData = useMemo(() => {
    if (!allContentfulCommon) {
      return {};
    }

    if (!allContentfulCommon.nodes?.length) {
      return {};
    }

    return (
      allContentfulCommon.nodes.find((item) => item.language === pageContext.language) ||
      allContentfulCommon.nodes.find((item) => item.language === 'en') ||
      allContentfulCommon.nodes[0]
    );
  }, [allContentfulCommon, pageContext]);

  const pageData = useMemo(() => {
    const contentfulData = Object.values(data || {})[0];
    if (!contentfulData) {
      return {};
    }

    if (!contentfulData.nodes?.length) {
      return {};
    }

    return contentfulData.nodes.find((item) => item.language === pageContext.language) || contentfulData.nodes[0];
  }, [data, pageContext]);

  return (
    <pageDataContext.Provider
      value={{
        common: commonData,
        data: pageData,
        location,
        pathname,
      }}
    >
      {children}
    </pageDataContext.Provider>
  );
}
