import React, { useEffect, useMemo } from 'react';
import { GlobalUIProvider } from '@/components/GlobalUIProvider';
import { parse } from 'query-string';
import { storeAccessTokens, stores, LOCALE_ASCRIPTION_MAP } from '@@/config';
import { ErrorBoundary } from 'react-error-boundary';
import { CrashError, DeploymentError } from '@/utils/error';
import * as Sentry from '@sentry/gatsby';
import { AppContext, setAppContextValue } from './AppProviderContext';
import { PageDataProvider } from '../PageDataProvider';
import { ShopProvider } from '../ShopProvider';

export function AppProvider({ data, location, pageContext, children }) {
  const { app: appFromQuery } = parse(location.search);

  const appContextValue = useMemo(() => {
    if (appFromQuery && appFromQuery !== pageContext.app) {
      // 优先app参数判断
      let store = stores.find((item) => item.app === appFromQuery || item?.appAlias?.includes(appFromQuery));

      if (!store) {
        store = stores.find((item) => item.brand === pageContext.brand && item.country === 'us') || stores[0];
      }

      return {
        app: store.app,
        language: store.lang,
        brand: process.env.BRAND,
        store: store.domain,
        locale: store.locale || store.country,
        defaultLocale: 'us',
        locales: pageContext.locales,
        storeAccessToken: storeAccessTokens[LOCALE_ASCRIPTION_MAP[store.country] || store.country],
        pathname: location.pathname,
        pathPrefix: store.country !== 'us' ? `/${store.locale || store.country}/` : '/',
        isApp: store.type === 'app',
        location,
        shopifyDomain: store.shopifyDomain,
      };
    }
    return pageContext;
  }, [pageContext, location, appFromQuery]);

  setAppContextValue(appContextValue);

  useEffect(() => {
    if (
      process.env.ENVIRONMENT === 'development' &&
      !(window.location.origin.includes('beta.passport') || window.location.origin.includes('localhost'))
    ) {
      Sentry.captureException(new DeploymentError(appContextValue.app), {
        tags: { app: appContextValue.app, type: 'DeploymentError' },
      });
    }
  }, [appContextValue]);

  return (
    <AppContext.Provider value={appContextValue}>
      <ErrorBoundary
        fallback={<div>Something went wrong</div>}
        onError={(error) => {
          Sentry.captureException(new CrashError(error.message), {
            tags: {
              app: appContextValue.app,
              type: 'CrashError',
            },
          });
        }}
      >
        <ShopProvider app={appContextValue.app} storeAccessToken={appContextValue.storeAccessToken}>
          <PageDataProvider
            location={location}
            pageContext={appContextValue}
            language={appContextValue.language}
            data={data}
          >
            <GlobalUIProvider>{children}</GlobalUIProvider>
          </PageDataProvider>
        </ShopProvider>
      </ErrorBoundary>
    </AppContext.Provider>
  );
}
